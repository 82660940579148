Vue.component('footerSectionComponent', {
    data : function(){
        return {year:new Date().getFullYear()}
    },
    template: `<footer class="page-footer">
                <div class="container">
                    <div class="row">
                        <div class="col 12 m2 s12">
                            <h5>Servicios</h5>
                            <ul>
                              <li><router-link to="/company/sso" target="_blank">One Stop Shopping</router-link></li>
                              <li><router-link to="/company/catalogo" target="_blank">Catálogos</router-link></li>
                              <li><router-link to="/company/ecp" target="_blank">Ec Personalizado</router-link></li>
                              <li><router-link to="/company/pedidos" target="_blank">Pedidos Fáciles</router-link></li>
                              <li><router-link to="/company/entregas" target="_blank">Entregas en todo el País</router-link></li>
                            </ul>
                        </div>
                        <div class="col 12 m2 s12">
                            <h5>Sobre Nosotros</h5>
                            <ul>
                              <li><router-link to="/company/vmv" target="_blank">Misión y Valores</router-link></li>
                              <li><router-link to="/company/etica" target="_blank">Código de ética</router-link></li>
                              <li><router-link to="/company/beneficios" target="_blank">Beneficios</router-link></li>
                              <li><router-link to="/company/bancaria" target="_blank">Información Bancaria</router-link></li>
                              <li><router-link to="/company/legajo" target="_blank">Legajo Impositivo</router-link><br>
                            </li>
                            </ul>
                        </div>
                        <div class="col 12 m2 s12">
                            <h5>Contáctenos</h5>
                            <ul>
                              <li><router-link to="/company/clientes" target="_blank">Clientes</router-link></li>
                              <li><router-link to="/company/proveedores" target="_blank">Proveedores</router-link></li>
                              <li><router-link to="/company/donde" target="_blank">Dónde Estamos</router-link></li>
                            </ul>
                        </div>
                        <div class="col 12 m2 s12">
                            <h5>RSE</h5>
                            <ul>
                                  <li><router-link to="/company/principios"> Nuestros Principios</router-link></li>
                                  <li><router-link to="/company/ambiental" target="_blank">Enfoque ambiental</router-link></li>
                                  <li><router-link to="/company/social" target="_blank">Enfoque Social</router-link></li>
                                  <li><router-link to="/company/economico" target="_blank">Enfoque Económico</router-link></li>
                            </ul>
                        </div>
                        <div class="col 12 m4 s12">
                            <div class="row">
                                <ul class="col 12 s12">
                                    <li>SERVICIO DE ATENCIÓN <br>AL CLIENTE TEL. 0-810-333-9240 <a href=""></a></li>
                                </ul>
                                 <div class="col 12 s12">
                                    <div class="col s3">
                                        <img src="../static/ecommerce/assets/img/LogoSecurity.png" style="max-width: 100px;">
                                    </div>    
                                    <div class="col s5">
                                        <img src="../static/ecommerce/assets/img/DATAWEB.jpg" style="max-width: 100px;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>`,
});
